<script setup>
import { computed } from 'vue';
import { useErrorProps, useInputProps, useLabelProps } from '../composables/input.js';
import FieldLabel from '../field/FieldLabel.vue';
import FieldError from '../field/FieldError.vue';

defineOptions({
    inheritAttrs: false,
});

const props = defineProps({
    ...useInputProps,
    ...useLabelProps,
    ...useErrorProps,
    value: null,
    title: {
        type: String,
        required: true,
    },
    subtitle: {
        type: String,
        default: null,
        required: false,
    },
    type: {
        type: String,
        default: 'button',
        validator(value) {
            return ['basic', 'button', 'no-check'].includes(value);
        },
    },
});

const emit = defineEmits(['update:modelValue']);

function handleChange() {
    emit('update:modelValue', props.value);
}

const isChecked = computed(() => props.modelValue === props.value);
</script>

<template>
  <section class="big-radio-input-field">
    <FieldLabel :label="label" />
    <div
      class="big-radio-input"
      :class=" type"
    >
      <input
        :id="id"
        ref="input"
        type="radio"
        :value="value"
        :name="name"
        :checked="isChecked"
        :disabled="disabled"
        v-bind="$attrs"
        @change="handleChange"
      >
      <label :for="id">
        <span class="radio-title">
          {{ title }}
        </span>
        <span
          v-if="subtitle"
          class="radio-subtitle"
        >
          {{ subtitle }}
        </span>
      </label>
    </div>
    <FieldError :error="error" />
  </section>
</template>

<style lang="scss" scoped>
@import './style/BigRadioInput.scss';
</style>
